@import '../../styles/propertySets.css';

.chat-widget {
  z-index: 9999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 432px;
  height: 807px;
  /* height: 672px; */

  border-radius: 16px;
  overflow: hidden;
  display: none;
  backdrop-filter: blur(18px);
  transition: background-color 0.3s ease;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  /* transition: all 0.3s ease; */
}
.chat-widget:hover {
  background-color: rgba(255, 255, 255, 0.7);
}
.chat-widget.open {
  height: auto;
}

.chat-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #002ea3;
  z-index: 2;
}

.chat-header button {
  font-size: 2.1em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
}

.chat-body {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;
}

.chatMessages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  overflow-x: hidden;
  /* overflow-y: auto; */
  /* padding: 10px; */
  /* margin-bottom: 103px; */
}

.chatMessage {
  margin-bottom: 16px;
}

.userMessage {
  display: inline-block;
  padding: 6px 16px;
  background-color: #007aff;
  max-width: 287px;
  color: #fff;
  border-radius: 16px 0px 16px 16px;
  line-height: 1.6;
  text-align: left;
  font-weight: 400;
  word-break: break-word;
  /* border: 1px solid #d2d6da; */
}

.bot-message {
  display: inline-block;
  padding: 8px 16px;
  background-color: #fff;
  filter: drop-shadow(0px 8px 20px rgba(20, 20, 20, 0.12));
  border-radius: 0px 16px 16px 16px;
  text-align: left;
  line-height: 1.6;
  /* border: 1px solid #d2d6da; */
  font-weight: 400;
  max-width: 333px;
  word-break: break-word;
  /* padding-bottom: 35px; */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.bot-message button,
.chatMessage .bookmark-button {
  float: right;
  border: none;
  cursor: pointer;
}

.bookmark-button:hover,
.bookmarked-button-true:hover {
  opacity: 0.7;
}
.bookmark-button,
.bookmarked-button-true {
  /* animation-name: pulse;
  animation-duration: 0.7s; */
}
.bookmark-button:focus {
  /* outline: none; */
}

/* @keyframes bookmark-fill {
  0% {
    opacity: 100%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    opacity: 0%;
  }
} */

.chat-tabs {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  background: #ffffff;
  height: 35px;
  align-content: flex-end;
}
.chat-tab {
  width: 50%;
  display: flex;
  justify-content: center;
}

.chat-tab button {
  width: 70%;
}

/* ! TEMPORARY */

.bookmark-body .bookmark-button,
.bookmark-body .bookmarked-button-true {
  display: none;
}

.bookmark-message {
  display: inline-block;
  padding: 8px 16px;
  background-color: #fff;
  filter: drop-shadow(0px 8px 20px rgba(20, 20, 20, 0.12));
  border-radius: 16px 16px 16px 16px;
  text-align: left;
  line-height: 1.6;
  /* border: 1px solid #d2d6da; */
  font-weight: 400;
  word-break: break-word;
}

.bookmark {
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  overflow-y: scroll;
  /* overflow-y: auto; */
  /* padding: 10px; */
  /* margin-bottom: 48px; */
  height: 100%;
}

.bookmark-body {
  height: 100%;
  padding-bottom: 103px;
}
.chat-input {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f3fcff;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 8%);
  z-index: 2;
}

.chat-input input {
  flex-grow: 1;
  padding: 3px;
  padding-left: 10px;
  border: none;
  border-radius: 16px;
  background-color: #fff;
  margin-right: 10px;
  border: 1px solid #d2d6da;
  color: black;
  margin-bottom: 0px;
  font-size: 13px;
  padding-right: 65px;
}

.chat-input button {
  border: none;
  background-color: #002ea3;
  color: white;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 29px;
}
.chat-input button:hover {
  cursor: pointer;
  background-color: #007aff;
}
.close-button:hover {
  color: rgb(255, 183, 0);
}

.chat-header h3 {
  color: white;
  margin: 0;
  gap: 5px;
  display: flex;
  align-content: center;
  align-items: flex-end;
  font-weight: 500;
}

.close-button {
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.95);
}

.chat-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* .chatMessages {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 48px;
} */

.chatMessages::-webkit-scrollbar {
  width: 10px;
}
.bookmark-messages::-webkit-scrollbar {
  width: 10px;
}

.chatMessages::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.bookmark-messages::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

/* .chatMessage {
  margin-bottom: 10px;
} */

/* .userMessage {
  text-align: right;
}

.bot-message {
  text-align: left;
} */

.chat-input button:hover {
  cursor: pointer;
  background-color: #002ea3;
}
@media only screen and (max-height: 600px) {
  .chat-widget {
    bottom: 0;
  }
}

@media only screen and (max-height: 480px) {
  .chat-widget {
    width: 100%;
    height: 400px;
    bottom: 0;
    right: 0;
  }
}

.resizable-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: #0084ff;
  border-radius: 50%;
  cursor: nwse-resize;
  z-index: 1;
}

.chat-widget.visible {
  right: 0;
}

.chat-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #002ea3;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
  z-index: 1001;
}

@media only screen and (max-width: 600px) {
  .chat-widget {
    bottom: 0;
  }
  .losi-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .losiMessageOuter .losi-icon .chat-icon img,
  .losiMessageOuter .losi-icon .chat-icon svg {
    width: 22px !important;
    height: 100% !important;
    align-self: center;
    margin-bottom: 2px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .chat-widget {
    width: 100%;
    height: 99%;
    bottom: 0px;
    border-radius: 16px 16px 0px 0px;
    /* margin-right: 5px;
    margin-left: 5px; */
    right: 0px;
  }
  .chat-input input {
    padding: 6px;
    padding-right: 64px;
    padding-left: 12px;
  }

  .chat-input button {
    padding: 3px 6px;
    border-radius: 12px;
  }
  .chat-input button svg {
    margin-left: 12px;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 12px;
  }
  .chat-icon {
    display: flex;
    width: 42px;
    align-items: center;
    justify-content: center;
  }
  .chat-icon img,
  .chat-icon svg {
    margin: 0px !important;
  }
}

.chat-toggle:hover {
  cursor: pointer;
  background-color: #0084ff;
}
.user-icon {
  background-color: var(--matterColor);
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px;
}
.losi-icon {
  background-color: #002ea3;
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
}
/* .userMessage-inner {
  width: 253px;
} */
.chatMessage p {
  margin: 0px;
  padding: 0px;
}
div.losiMessageOuter.thinkingContainer > div > div,
body
  > div:nth-child(14)
  > div
  > div.chat-body
  > div.chatMessages
  > div.losiMessageOuter.thinkingContainer
  > button
  > i
  > svg
  > g
  > g:nth-child(3)
  > path,
body
  > div:nth-child(14)
  > div
  > div.chat-body
  > div.chatMessages
  > div.losiMessageOuter.thinkingContainer
  > button
  > i
  > svg
  > g
  > g:nth-child(4)
  > path {
  opacity: 1;
  /* animation-name: pulse; */
  /* animation-duration: 4s; */
  /* animation-fill-mode: forwards; Keep the element visible at 100% opacity after animation ends */
  /* animation-iteration-count: infinite; */
  /* animation-timing-function: ease-in-out; */
}
.thinkingContainer > .losi-icon {
  animation: color-change 8s infinite;
}

@keyframes color-change {
  0% {
    background-color: #002ea3;
  }
  50% {
    background-color: #0084ff;
  }
  100% {
    background-color: #002ea3;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.chatMessage ul li {
  list-style: inside;
  list-style-type: circle;
}
.chatMessage ol li {
  list-style-type: decimal;
}
.losi-icon .chat-icon img,
.losi-icon .chat-icon svg {
  width: 24px;
}
.user-icon .chat-icon img {
  width: 24px;
}
.losiMessageOuter {
  display: flex;
  gap: 10px;
}
.userMessageOuter {
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.chat-icon svg {
  margin-right: 0px !important;
  margin-bottom: 4px;
  width: 42px;
}
.chat-input button img,
.chat-input button svg {
  margin-left: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 12px;
}
/* .chat-icon img:hover {
  margin-right: 0px;
  width: 42px;
  margin-bottom: 4px;
} */
.active-eye {
  opacity: 0;
  filter: drop-shadow(0px 4px 4px #82d616);
  transition: all 0.8s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  /* animation-name: pulse;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  /* Keep the element visible at 100% opacity after animation ends */
  /* animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; */
}
#chat-toggle {
  font-family: 'Open Sans', sans-serif;
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 9999;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: #2d2926;
  box-shadow: 0px 0px 0px #284269;
  color: white;
  font-weight: lighter;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.95;
  transition: background-color 0.3s ease-in-out;
}

#chat-toggle:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}

#chat-toggle i {
  font-size: 30px;
  line-height: 60px;
}

.chat-toggle:hover path:nth-child(3) {
  fill: #82d616;
  filter: drop-shadow(0px 4px 4px #82d616);
  transition: all 0.8s ease;
}

.chat-toggle:hover path:nth-child(4) {
  fill: #82d616;
  filter: drop-shadow(0px 4px 4px#82d616);
  /* box-shadow: 0px 4px 8px #82d616; */
  transition: all 0.8s ease;
}
.chat-toggle path:nth-child(3) {
  transition: all 0.8s ease;
}

.chat-toggle path:nth-child(4) {
  transition: all 0.8s ease;
}
.chat-bubble {
  padding: 6px 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
  align-items: center;
}

.chat-bubble .typing-indicator {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* position: absolute;
  bottom: -12px;
  left: calc(100% + 8px); */
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #d6d6d6;
  animation: typing-dot 1s infinite;
}

.chat-bubble .typing-indicator::before,
.chat-bubble .typing-indicator::after {
  content: '';
  position: absolute;
  top: auto;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #d6d6d6;
  transition: opacity 1s ease;
}

.chat-bubble .typing-indicator::before {
  left: -8px;
  opacity: 0.6;
}

.chat-bubble .typing-indicator::after {
  left: -16px;
  opacity: 0.3;
}

.chat-bubble .typing-indicator .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: #d6d6d6;
  animation: typing-dot 1s infinite;
}

.chat-bubble .typing-indicator .dot:nth-child(2) {
  animation-delay: 0.8s;
}

.chat-bubble .typing-indicator .dot:nth-child(3) {
  animation-delay: 1.6s;
}

@keyframes typing-dot {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
