.root {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
  border-radius: var(--borderRadius)
}

.mapRoot { 
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadius)
}
