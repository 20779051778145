@import '../../styles/propertySets.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
.category {
  outline: none;
    background-color: #fbffff;

    text-decoration: none;


  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColor);
  margin-bottom: 5px;
  @apply --marketplaceButtonFontStyles;

  /* Dimensions */
  /* display: block; */
  width: 200px;
display: flex;
  margin: 0;
  border-radius: 80px;
 background-color: var(--matterColorBright);;
  /* padding: 1px 6px; */
  justify-content: flex-start;
  margin:  10px 0px 5px 0px;
  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  /* padding: 6px 12px; */

  @media (--viewportMedium) {
    justify-content: flex-start;

    font-size: 18px;

  }

  /* Borders */
  /* border: none; */
 

  /* Colors */
  /* background-color: var(--marketplaceColor); */


  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  /* cursor: pointer; */

  align-items: center;
  @media (--viewportSmall) {
    min-height:  39px;
    justify-content: flex-start;
    display: flex;
  }

}

.category img {
  width: 32px;
  @media (--viewportMedium) {
    margin-left: 8px;
  }
}
.checkIcon {
}

.itemContainer {
  margin-left: 12px;
  @media (--viewportMedium) {
  margin-left: 22px;
  }
}
.hidden {
  /* visibility: hidden; */
  display: none;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  @apply --marketplaceBodyFontStyles;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
}

.notSelectedLabel {
  color: var(--matterColorNegative);
  position: relative;

  /* line-through */
  background-image: linear-gradient(
    transparent 10px,
    var(--matterColorNegative) 10px,
    var(--matterColorNegative) 11px,
    transparent 11px
  );

  @media (--viewportMedium) {
    background-image: linear-gradient(
      transparent 12px,
      var(--matterColorNegative) 12px,
      var(--matterColorNegative) 13px,
      transparent 13px
    );
  }
}
