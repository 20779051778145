@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-flex;
  margin-bottom: 12px;
 
}

.input {
  /* position: absolute; */
  opacity: 0;
  height: 0;
  width: 0;

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: flex;
    position: absolute;
  }
  &:checked + label .notChecked {
    display: none;
  }

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .notChecked,
  &:hover + label .required,
  &:focus + label .notChecked,
  &:focus + label .required,
  &:checked + label .required {
   color:blue;
  }

  &:checked + label .notChecked {
    stroke: none;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    /* color: var(--matterColorDark); */
  }
}

.label {
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.radioButtonWrapper {
  /* This should follow line-height */
  height: 42px;
  margin-top: 20px;
  margin-right: 5px;
  align-self: baseline;
  /* position: absolute; */
  display: inline-flex;
  align-items: flex-end;
  cursor: pointer;
}

.checkedStyle {
  /* stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor); */
  position: absolute;
  display: none;
}

.checked {
  display: none;
  position: absolute;
}

.notChecked {
  /* stroke: var(--matterColorAnti); */
  &:hover {
    stroke: pink;
  }
}

.required {
  /* stroke: var(--attentionColor); */
  &:hover {
    stroke: pink;
  }
}

.text {
  @apply --marketplaceListingAttributeFontStyles;
  /* color: var(--matterColor); */
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.AccountTypeButton {
  position: absolute;
}
