@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    text-align: left;
  }
}
.location {
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.locations {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 0px;

  flex-wrap: nowrap;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
    align-items: flex-start;
  }
}
.bold {
  font-weight: 800;
  color: var(--marketplaceColor);
}
.location {
  width: 100%;
  margin-top: 25px;

  flex-basis: 48%;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
  border-radius: 25px;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}
.heroButtonBlue {
  @apply --marketplaceButtonStyles;
  composes: animation;
  box-shadow: 0px 1px 20px 0px rgb(20 20 20 / 40%);

  animation-delay: 0.8s;
  border-radius: 16px;
  background: var(--marketplaceColor);
  margin: 26px 0 0 0;
  color: white;
  &:hover {
    background: #007aff;
    color: white;
  }
  &:active {
    background: var(--matterColor);
    color: white;
  }
  @media (--viewportMedium) {
    display: block;
    width: 240px;
    margin: 46px 0 0 0;
  }
}

.shield {
  width: 100%;
  height: 100%;
  fill: var(--marketplaceColor);
  transition: var(--transitionStyleButton);
}
