@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  margin-top: 0;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}
.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    text-align: left;
  }
}
.subtitle {
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}
.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
.heroButtonBlue {
  @apply --marketplaceButtonStyles;
  composes: animation;
  box-shadow: 0px 1px 20px 0px rgb(20 20 20 / 40%);

  animation-delay: 0.8s;
  border-radius: 16px;
  background: var(--marketplaceColor);
  margin: 26px 0 0 0;
  color: white;
  &:hover {
    background: #007aff;
    color: white;
  }
  &:active {
    background: var(--matterColor);
    color: white;
  }
  @media (--viewportMedium) {
    display: block;
    width: 240px;
    margin: 46px 0 0 0;
  }
}
