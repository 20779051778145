@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}
/* .form:nth-child(1) {
display: none;
} */


.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.ButtonTabNavHorizontal > div > button{

 
}

.inline {
  display: inline-flex;
  
}
.inline > input{
 width: 0px;
  
}