@import '../assets/sanitize.css';
@import './customMediaQueries.css';
@import './propertySets.css';

/* ================ Custom Properties aka CSS variables ================ */

/**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */

:root {
  /* ================ Colors ================ */

  --marketplaceColor: #152192;
  --marketplaceColorAlt: #007aff;
  --marketplaceColorLight: #e6e6e6;
  --marketplaceColorDark: #3b3b3b;

  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %230095b3;

  --successColor: #2ecc71;
  --successColorDark: #239954;
  --successColorLight: #f0fff6;
  --failColor: #ff0000;
  --failColorLight: #fff0f0;
  --attentionColor: #ffaa00;
  --attentionColorLight: #fff7f0;
  --bannedColorLight: var(--marketplaceColorLight);
  --bannedColorDark: var(--marketplaceColor);

  --matterColorDark: #000000;
  --matterColor: #4a4a4a;
  --matterColorAnti: #b2b2b2;
  --matterColorNegative: #e7e7e7;
  --matterColorBright: #fcfcfc;
  --matterColorBlue: #f3fcff;
  --matterColorLight: #ffffff;

  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);
  /* ================ Font ================ */
  --fontFamily: 'poppins', Helvetica, Arial, sans-serif;

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;
  --fontWeightVeryBold: 800;

  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 26px;
  --borderRadiusMobileSearch: 16px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar related ================ */

  --topbarHeight: 66px;
  --topbarHeightDesktop: 80px;

  --TopbarMobileMenu_topMargin: 96px;

  --Topbar_logoHeight: 26px;
  --CheckoutPage_logoHeight: 26px;
  --CheckoutPage_logoHeightDesktop: 27px;

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ LocationAutocompleteInput bottom attribution padding ================ */

  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
  --locationAutocompleteBottomPadding: 8px;

  --LocationAutocompleteInput_inputHeight: 50px;
  --LocationAutocompleteInput_sidePaddingDesktop: 36px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ DateInput, DateRangeInput, DateRangeController ================ */
  --ReactDates_selectionHeight: 36px;
  --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

  /* ================ SectionHero ================ */

  --SectionHero_desktopTitleMaxWidth: 625px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ LandingPage ================ */
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  /* ================ EditListingAvailabilityForm, ManageAvailabilityCalendar ================ */
  --ManageAvailabilityCalendar_gridColor: #e0e0e0;
  --ManageAvailabilityCalendar_availableColor: #ffffff;
  --ManageAvailabilityCalendar_availableColorHover: #fafafa;
  --ManageAvailabilityCalendar_blockedColor: #ebebeb;
  --ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
  --ManageAvailabilityCalendar_reservedColor: #e6fff0;
  --ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
  --ManageAvailabilityCalendar_failedColor: #fff2f2;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;
}

/* ================ Global element styles ================ */

body {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;
  width: 100vw; /* prevent horizontal scrollbars */
  background-color: var(--matterColorBright);
}
@media only screen and (max-width: 350px) {
  .logo {
    width: 140px;

    @media (--viewportMedium) {
      flex-direction: row;
      width: 240px;
    }
  }
}

::selection {
  background: var(--marketplaceColor); /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}

::-moz-selection {
  background: var(--marketplaceColor); /* Gecko Browsers */
  color: var(--matterColorLight);
}

a {
  @apply --marketplaceLinkStyles;
}

h1 {
  @apply --marketplaceH1FontStyles;
}
h2 {
  @apply --marketplaceH2FontStyles;
}
h3 {
  @apply --marketplaceH3FontStyles;
}
h4 {
  @apply --marketplaceH4FontStyles;
}
h5 {
  @apply --marketplaceH5FontStyles;
}
h6 {
  @apply --marketplaceH6FontStyles;
}

li {
  @apply --marketplaceDefaultFontStyles;
}

p,
pre {
  @apply --marketplaceBodyFontStyles;
}

/* ================ Normalisations ================ */

html {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

legend,
label {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

button {
  font-family: Helvetica, Arial, sans-serif;
}

select {
  @apply --marketplaceSelectStyles;
  @apply --marketplaceInputFontStyles;
}

input {
  @apply --marketplaceInputStyles;
  @apply --marketplaceInputFontStyles;
}

textarea {
  @apply --marketplaceInputStyles;
  @apply --marketplaceInputFontStyles;

  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  box-sizing: content-box;
  padding: 0 0 10px 0;

  &::placeholder {
    padding: 0 0 10px 0;
  }

  @media (--viewportMedium) {
    padding: 4px 0 10px 0;
  }
}

.fontsLoaded {
  & body {
    font-family: 'poppins', Helvetica, Arial, sans-serif;
  }
  & button {
    font-family: 'poppins', Helvetica, Arial, sans-serif;
  }
}

/* chat widget elements */

b {
  color: white;
  font-family: Manrope;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  background-color: transparent;
  transition: none;
  padding-bottom: 10px;
}

b:hover {
  color: red;
}

p1 {
  color: white;
}

.chat-widget {
  z-index: 9999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 432px;
  /* height: 807px; */
  /* height: 672px; */
  height: 82%;
  overflow-y: scroll;
  border-radius: var(--borderRadius);
  overflow: hidden;
  display: none;
  backdrop-filter: blur(18px);
  transition: background-color 0.3s ease;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  /* transition: all 0.3s ease; */
  animation: fadeIn 0.5s cubic-bezier(0, 0, 0.2, 1) forwards;
}

.chat-widget.open {
  height: auto;
}

.chat-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #002ea3;
  z-index: 2;
}

.chat-header button {
  font-size: 2.1em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
}

.chat-body {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  padding-bottom: 56px;
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  overflow-x: hidden;
  overflow-y: scroll;
  /* padding: 10px; */
  margin-bottom: 64px;
}

.chat-message {
  margin-bottom: 16px;
  animation: fadeIn 0.5s cubic-bezier(0, 0, 0.2, 1) forwards;
}

.user-message {
  display: inline-block;
  padding: 6px 16px;
  background-color: #007aff;
  max-width: 287px;
  color: #fff;
  border-radius: 16px 0px 16px 16px;
  line-height: 1.6;
  text-align: left;
  font-weight: 400;
  word-break: break-word;
  /* border: 1px solid #d2d6da; */
}
.bookmark-button {
  display: inline-flex;
  justify-content: center;
  border: 13px solid black;
  column-gap: 5px;
  color: #9f9f9f;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.25s;
  align-items: center;
  box-shadow: 0 0 5px rgb(0, 0, 0.25s);
  font-size: 17px;
  padding: 0px;
}
.chat-messages .bookmarked-button-true {
  /* height: 50px; */

  display: inline-flex;
  justify-content: center;

  /* column-gap: 5px; */
  color: #002ea3;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.25s;
  align-items: center;
  box-shadow: 0 0 5px rgb(0, 0, 0.25s);
  padding: 0px;
  font-size: 17px;
  /* animation: ribbon 0.25s cubic-bezier(0.77, 0, 0.17, 1) forwards; */
}
.bookmarked-button-true svg,
.bookmark-button svg {
  margin-right: 0px;
}
.bookmark-body .bookmarked-button-true,
.bookmark-body .bookmark-button {
  display: none !important;
}
.bookmark-button:focus {
  animation: ribbon 0.25s cubic-bezier(0.77, 0, 0.17, 1) forwards;
}
.bookmarked-button-true:focus > .fa-bookmark {
  animation: ribbonInvert 0.25s cubic-bezier(0.77, 0, 0.17, 1) forwards;
}

@keyframes ribbon {
  0% {
    transform: scaleY(0.1);
  }
  25% {
    transform: scaleY(0.1);
  }
  100% {
    color: #002ea3;
  }
}
@keyframes ribbonInvert {
  0% {
    transform: scaleY(0.1);
  }
  25% {
    transform: scaleY(0.1);
  }
  100% {
    color: #9f9f9f;
  }
}
.bot-message {
  display: inline-block;
  padding: 8px 16px;
  background-color: #fff;
  filter: drop-shadow(0px 8px 20px rgba(20, 20, 20, 0.12));
  border-radius: 0px 16px 16px 16px;
  text-align: left;
  line-height: 1.6;
  /* border: 1px solid #d2d6da; */
  font-weight: 400;
  max-width: 333px;
  word-break: break-word;
  /* padding-bottom: 35px; */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.bot-message button,
.chat-message .bookmark-button {
  float: right;
  border: none;
  cursor: pointer;
}

.bookmark-button:hover,
.bookmarked-button-true:hover {
  opacity: 0.7;
}
.bookmark-button,
.bookmarked-button-true {
  /* animation-name: pulse;
  animation-duration: 0.7s; */
}
.bookmark-button:focus {
  /* outline: none; */
}

/* @keyframes bookmark-fill {
  0% {
    opacity: 100%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    opacity: 0%;
  }
} */

.chat-tabs {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;

  height: 35px;
  align-content: flex-end;
  z-index: 99999;
}
.chat-tab {
  width: 50%;
  display: flex;
  justify-content: center;
  z-index: 99999;
  backdrop-filter: blur(18px);
  background-color: rgba(255, 255, 255, 0.9);
}

.chat-tab button {
  width: 100%;
  height: 37px;
}

/* ! TEMPORARY */

.bookmark-body .bookmark-button,
.bookmark-body .bookmarked-button-true {
  display: none;
}

.bookmark-message {
  display: inline-block;
  padding: 8px 16px;
  background-color: #fff;
  filter: drop-shadow(0px 8px 20px rgba(20, 20, 20, 0.12));
  border-radius: 16px 16px 16px 16px;
  text-align: left;
  line-height: 1.6;
  /* border: 1px solid #d2d6da; */
  font-weight: 400;
  word-break: break-word;
}

.bookmark {
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  overflow-y: scroll;
  /* overflow-y: auto; */
  /* padding: 10px; */
  /* margin-bottom: 48px; */
  height: 100%;
}

.bookmark-body {
  height: 100%;
  padding-bottom: 103px;
  background: #fff;
}
.chat-input {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f3fcff;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 8%);
  z-index: 2;
}

.chat-input input {
  flex-grow: 1;
  padding: 3px;
  padding-left: 10px;
  border: none;
  border-radius: 63px;
  background-color: #fff;
  margin-right: 10px;
  border: 1px solid #d2d6da;
  color: black;
  margin-bottom: 0px;
  font-size: 13px;
  padding-right: 44px;
}

.chat-input button {
  border: none;
  background-color: #007aff;
  color: white;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 28px;
  padding: 0px 9px;
  border-radius: 100%;
}
.chat-input button:hover {
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px #82d61669);
  transition: all 0.8s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.chat-input button svg {
  margin: 0px;
  transform: rotate(-90deg);
}

.close-button:hover,
#onboarding .close-button:hover,
#authentication .close-button:hover {
  color: rgb(255, 183, 0);
  border: none;
}

/* Position the arrow in the center */
/* .refresh-button strong {
  display: block;
  text-align: center;
  line-height: 1;
  animation: rotate-back 0.5s forwards;
} */
.refresh-button img {
  transition: rotate-back 1s forwards;
}
.refresh-button:hover {
  border: none !important;
}

/* Define the animation */
@keyframes rotate-back {
  0% {
    transform: scaleX(-1) rotate(-60deg);
  }
  100% {
    transform: scaleX(-1) rotate(-90deg);
  }
}
/* Rotate clockwise on hover */
.refresh-button:hover img {
  /* top: 18px; */
  color: rgb(255, 183, 0);
  animation: rotate-cw 0.5s forwards;
  transform: scaleX(-1);
}

/* Rotate anticlockwise on click */
.refresh-button:active img {
  animation: rotate-cw2 0.1s forwards;
  transform: scaleX(-1);
}

/* Define the animations */
@keyframes rotate-cw {
  0% {
    -webkit-transform: scaleX(-1) rotate(-90deg);
    -moz-transform: scaleX(-1) rotate(-90deg);
    -ms-transform: scaleX(-1) rotate(-90deg);
    -o-transform: scaleX(-1) rotate(-90deg);
    transform: scaleX(-1) rotate(-90deg);
  }
  100% {
    -webkit-transform: scaleX(-1) rotate(-150deg);
    -moz-transform: scaleX(-1) rotate(-150deg);
    -ms-transform: scaleX(-1) rotate(-150deg);
    -o-transform: scaleX(-1) rotate(-150deg);
    transform: scaleX(-1) rotate(-150deg);
  }
}

@keyframes rotate-cw2 {
  0% {
    -webkit-transform: scaleX(-1) rotate(-60deg);
    -moz-transform: scaleX(-1) rotate(-60deg);
    -ms-transform: scaleX(-1) rotate(-60deg);
    -o-transform: scaleX(-1) rotate(-60deg);
    transform: scaleX(-1) rotate(-60deg);
  }
  100% {
    -webkit-transform: scaleX(-1) rotate(-360deg);
    -moz-transform: scaleX(-1) rotate(-360deg);
    -ms-transform: scaleX(-1) rotate(-360deg);
    -o-transform: scaleX(-1) rotate(-360deg);
    transform: scaleX(-1) rotate(-360deg);
  }
}
.chat-header h3 {
  color: white;
  margin: 0;
  gap: 5px;
  display: flex;
  align-content: center;
  align-items: flex-end;
  font-weight: 500;
}

.close-button {
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.95);
}

.chat-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* .chat-messages {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 48px;
} */

.chat-messages::-webkit-scrollbar {
  width: 5px;
  border-radius: 12px;
}
.bookmark-messages::-webkit-scrollbar {
  width: 5px;
  border-radius: 12px;
}

#logged-in {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.chat-messages::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.bookmark-messages::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

/* .chat-message {
  margin-bottom: 10px;
} */

/* .user-message {
  text-align: right;
}

.bot-message {
  text-align: left;
} */

.chat-input button:hover path {
  cursor: pointer;
  color: #82d616 !important;
}
@media only screen and (max-height: 600px) {
  .chat-widget {
    bottom: 0;
    overflow-y: scroll;
  }
}
@media only screen and (min-height: 600px) {
  .refresh-button {
    border: none;
    background: none;
    font-size: 32px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.95);
    right: 60px;
    position: absolute;
    top: 19.5px;
  }
}

@media only screen and (max-height: 480px) {
  .chat-widget {
    width: 100%;
    height: 400px;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
  }
  .refresh-button {
    border: none;
    background: none;
    font-size: 32px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.95);
    right: 60px;
    position: absolute;
    top: 19.5px;
  }
}

.resizable-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: #0084ff;
  border-radius: 50%;
  cursor: nwse-resize;
  z-index: 1;
}

.chat-container {
  width: 100%;
  overflow: hidden;
}

.chat-widget.visible {
  right: 0;
}

.chat-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #002ea3;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
  z-index: 1001;
  animation: fadeIn 0.5s cubic-bezier(0, 0, 0.2, 1) forwards;
}

@media only screen and (max-width: 600px) {
  .chat-widget {
    bottom: 0;
  }

  .losi-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chat-message-outer .losi-icon .chat-icon img,
  .chat-message-outer .losi-icon .chat-icon svg {
    width: 22px !important;
    height: 100% !important;
    align-self: center;
    margin-bottom: 2px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .chat-widget {
    width: 100%;
    height: 99%;
    bottom: 0px;
    border-radius: var(--borderRadius) var(--borderRadius) 0 0;
    /* margin-right: 5px;
    margin-left: 5px; */
    right: 0px;
  }
  .refresh-button {
    border: none;
    background: none;
    font-size: 32px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.95);
    right: 60px;
    position: absolute;
    top: 19.5px;
  }
  .chat-input input {
    padding: 6px;
    padding-right: 40px;
    padding-left: 12px;
  }

  .chat-input button {
    padding: 4px 9px;
    border-radius: 100%;
  }
  .chat-input button svg {
    margin: 0px;
    transform: rotate(-90deg);
  }
  .chat-icon {
    display: flex;
    width: 42px;
    align-items: center;
    justify-content: center;
  }
  .chat-icon img,
  .chat-icon svg {
    margin: 0px !important;
  }
  .sCWrapMinimize.CenterPop {
    position: fixed;
    left: calc(100% - 179px) !important;
    transform: inherit !important;
    bottom: inherit !important;
    top: calc(100vh - 461px) !important;
    transform: rotate(-90deg) !important;
  }
}

.chat-toggle:hover {
  cursor: pointer;
  background-color: #0084ff;
}
.user-icon {
  background-color: var(--matterColor);
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px;
}
.losi-icon {
  background-color: #002ea3;
  border: none;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
}
/* .user-message-inner {
  width: 253px;
} */
.chat-message p {
  margin: 0px;
  padding: 0px;
}
div.chat-message-outer.losi-message-outer.thinking-container > div > div,
body
  > div:nth-child(14)
  > div
  > div.chat-body
  > div.chat-messages
  > div.chat-message-outer.losi-message-outer.thinking-container
  > button
  > i
  > svg
  > g
  > g:nth-child(3)
  > path,
body
  > div:nth-child(14)
  > div
  > div.chat-body
  > div.chat-messages
  > div.chat-message-outer.losi-message-outer.thinking-container
  > button
  > i
  > svg
  > g
  > g:nth-child(4)
  > path {
  opacity: 1;
  /* animation-name: pulse; */
  /* animation-duration: 4s; */
  /* animation-fill-mode: forwards; Keep the element visible at 100% opacity after animation ends */
  /* animation-iteration-count: infinite; */
  /* animation-timing-function: ease-in-out; */
}
.thinking-container > .losi-icon {
  animation: color-change 8s infinite;
}

@keyframes color-change {
  0% {
    background-color: #002ea3;
  }
  50% {
    background-color: #0084ff;
  }
  100% {
    background-color: #002ea3;
  }
}
/* @keyframes gradient-change {
  0% {
    background: linear-gradient(-180deg, #002ea3 0%, #0084ff 100%);
  }
  25% {
    background: linear-gradient(0deg, #002ea3 0%, #0084ff 100%);
  }
  50% {
    background: linear-gradient(180deg, #002ea3 0%, #0084ff 100%);
  }
  75% {
    background: linear-gradient(0deg, #002ea3 0%, #0084ff 100%);
  }
  100% {
    background: linear-gradient(-180deg, #002ea3 0%, #0084ff 100%);
  }
} */

@keyframes gradient-change {
  0% {
    /* background-position: 0% 50%; */
    background: linear-gradient(0deg, #0084ff 0%, #002ea3 100%);
  }
  50% {
    /* background-position: 100% 50%; */
    background: linear-gradient(180deg, #002ea3 0%, #0084ff 100%);
  }
  100% {
    /* background-position: 0% 50%; */
    background: linear-gradient(360deg, #0084ff 0%, #002ea3 100%);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.chat-message ul li {
  list-style: inside;
  list-style-type: circle;
}
.chat-message ol li {
  list-style-type: decimal;
}
.losi-icon .chat-icon img,
.losi-icon .chat-icon svg {
  width: 24px;
}
.user-icon .chat-icon img {
  width: 24px;
}
.chat-message-outer {
  display: flex;
  gap: 10px;
}
.user-message-outer {
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.chat-icon svg {
  margin-right: 0px !important;
  margin-bottom: 4px;
  width: 42px;
}
.chat-input button img {
  margin-left: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 12px;
}
/* .chat-icon img:hover {
  margin-right: 0px;
  width: 42px;
  margin-bottom: 4px;
} */
.active-eye {
  opacity: 0;
  filter: drop-shadow(0px 4px 4px #82d616);
  transition: all 0.8s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  /* animation-name: pulse;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  /* Keep the element visible at 100% opacity after animation ends */
  /* animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; */
}
#chat-toggle {
  font-family: 'Open Sans', sans-serif;
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 9999;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: #2d2926;
  box-shadow: 0px 0px 0px #284269;
  color: white;
  font-weight: lighter;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.95;
  transition: background-color 0.3s ease-in-out;
}

#chat-toggle:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}

#chat-toggle i {
  font-size: 30px;
  line-height: 60px;
}

.chat-toggle:hover path:nth-child(3) {
  fill: #82d616;
  filter: drop-shadow(0px 4px 4px #82d616);
  transition: all 0.8s ease;
}

.chat-toggle:hover path:nth-child(4) {
  fill: #82d616;
  filter: drop-shadow(0px 4px 4px#82d616);
  /* box-shadow: 0px 4px 8px #82d616; */
  transition: all 0.8s ease;
}
.chat-toggle path:nth-child(3) {
  transition: all 0.8s ease;
}

.chat-toggle path:nth-child(4) {
  transition: all 0.8s ease;
}
.chat-bubble {
  padding: 6px 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
  align-items: center;
}

.chat-bubble .typing-indicator {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* position: absolute;
  bottom: -12px;
  left: calc(100% + 8px); */
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #d6d6d6;
  animation: typing-dot 1s infinite;
}

.chat-bubble .typing-indicator::before,
.chat-bubble .typing-indicator::after {
  content: '';
  position: absolute;
  top: auto;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #d6d6d6;
  transition: opacity 1s ease;
}

.chat-bubble .typing-indicator::before {
  left: -8px;
  opacity: 0.6;
}

.chat-bubble .typing-indicator::after {
  left: -16px;
  opacity: 0.3;
}

.chat-bubble .typing-indicator .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: #d6d6d6;
  animation: typing-dot 1s infinite;
}

.chat-bubble .typing-indicator .dot:nth-child(2) {
  animation-delay: 0.8s;
}

.chat-bubble .typing-indicator .dot:nth-child(3) {
  animation-delay: 1.6s;
}

@keyframes typing-dot {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

/* Apply transition effects to the onboarding screen elements */
#onboarding h2,
#onboarding .close-button,
#onboarding h3,
#onboarding > .logo > img,
#onboarding #logged-in p,
#onboarding #logged-in,
#onboarding .step-counter,
#onboarding #logged-in h6,
#onboarding #logged-in .onboarding-form,
#onboarding button,
#login-text,
#login-form {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 1s ease, transform 1s ease;
  /* transition-delay: 10s; */
  /* reset transition delay on show */
}

/* Apply transition effects to the onboarding screen elements */
#onboarding > .logo > img:nth-child(1) {
  transition-delay: 1s;
}

#onboarding > .logo > img:nth-child(2) {
  transition-delay: 1.25s;
}
#onboarding .close-button {
  transition-delay: 1.5s;
}
#onboarding .refresh-button {
  transition-delay: 1.75s;
}
#onboarding .refresh-button img {
  -webkit-transform: translateY(-20px) scaleX(-1) rotate(30deg);
  -moz-transform: translateY(-20px) scaleX(-1) rotate(30deg);
  -ms-transform: translateY(-20px) scaleX(-1) rotate(30deg);
  -o-transform: translateY(-20px) scaleX(-1) rotate(30deg);
  transform: translateY(-20px) scaleX(-1) rotate(30deg);
}

#onboarding h2 {
  margin-bottom: 0px;
  transition-delay: 2s;
}
#onboarding h3 {
  transition-delay: 2.5s;
}
#login-text {
  transition-delay: 3s;
}
#login-form {
  transition-delay: 3.5s;
}
#login-section h2 {
  transition-delay: 3s;
}
#onboarding #logged-in p {
  transition-delay: 3.5s;
  font-weight: 400;
  padding-bottom: 3px;
}

#onboarding #logged-in .onboarding-form {
  transition-delay: 4s;
}

#onboarding button {
  transition-delay: 4.25s;
}
#onboarding button:hover {
  color: white;
  border: 2px solid var(--successColor);
}
#onboarding .step-counter {
  transition-delay: 6.5s;
}
#onboarding #logged-in h6 {
  color: #bababa;
  transition-delay: 5s;
  width: 70%;
}

#onboarding label {
  font-weight: var(--fontWeightVeryBold);
}
/* Show the onboarding screen elements gradually */
#onboarding.show h2,
#onboarding.show .close-button,
#onboarding.show h3,
#onboarding.show > .logo > img,
#onboarding.show #logged-in p,
#onboarding.show #logged-in,
#onboarding.show .step-counter,
#onboarding.show #logged-in h6,
#onboarding.show #logged-in .onboarding-form,
#onboarding.show button,
#login-text.show,
#onboarding.show #login-section .onboarding-form {
  opacity: 1;
  /* transition: opacity 10s ease, transform 10s ease; */
  transform: translateY(0);
  /* transition-delay: 10s; */
  /* reset transition delay on show */
}

#onboarding.show .refresh-button img {
  transition: opacity 1s ease, transform 4s ease;
  transform: translateY(0) scaleX(-1) rotate(-90deg);
}
.onboarding-form fieldset {
  border: none;
  width: 100%;
}
.onboarding-form fieldset button {
  @apply --marketplaceButtonStyles;
  background-color: white;
  color: var(--marketplaceColor);
}

#onboarding,
#authentication {
  flex-grow: 1;
  padding: 16px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* flex-grow: 1; */
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  /* overflow-y: auto; */
  /* padding: 10px; */
  /* margin-bottom: 48px; */
  text-align: center;
  background: linear-gradient(0deg, #0084ff 0%, #002ea3 100%);
  color: white;
  animation: gradient-change 10s linear infinite;
  /* background-size: 400% 400%; */
  transition: fadeIn 0.5s ease-out, filter 0.5s ease-out;
}
/* Hide scrollbar for Chrome and Safari */
#onboarding::-webkit-scrollbar,
#authentication::-webkit-scrollbar {
  display: none;
}
#onboarding a,
#authentication a {
  color: #ffffff;
  font-weight: var(--fontWeightVeryBold);
  text-decoration: wavy;
  border-bottom: 2px solid #f2f2f2;
  padding-bottom: 5px;
}
#onboarding.blur-out {
  /* Add blur-out animation */
  opacity: 0;
  transition: opacity 0.5s ease-out, filter 0.5s ease-out;
  filter: blur(10px);
}
.onboarding-form {
  align-items: center;
  justify-content: center;
  width: 80%;
}

.onboarding-form input,
.onboarding-form select {
  border-bottom-color: white;
  border-radius: 5px;
  padding: 5px 15px;
  text-align: center;
}

#onboarding img,
#authentication img {
  width: 130px;
}

#onboarding .close-button,
#authentication .close-button {
  border: none;
  background: none;
  font-size: 32px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.95);
  right: 20px;
  position: absolute;
  top: 20px;
}

.onboarding-form select {
  appearance: none; /* removes default appearance */
  background-color: white;
  color: #000000;
  border: 9px solid white;
  padding: 5px;

  /* add any other styles you want */
}

.chat-container.blur-in {
  /* Add blur-in animation */

  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, filter 0.5s ease-in-out;
}

#authentication {
  opacity: 0; /* start with 0 opacity */
  animation: fadeIn 1s ease forwards; /* use CSS animation to fade in */
  animation-delay: 0.5s; /* add a 1 second delay before the animation */
}
#authentication-inner {
  opacity: 0; /* start with 0 opacity */
  animation: fadeIn 1s ease forwards; /* use CSS animation to fade in */
  animation-delay: 1s; /* add a 1 second delay before the animation */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* start with 0 opacity */
  }

  to {
    opacity: 1; /* end with 1 opacity */
  }
}

#intro-text::after {
  /* Add cursor animation */
  /* content: '_'; */
  animation: blink 0.5s step-end 22;
  /* transition-delay: 4s; */
}

@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.logo img:nth-child(2) {
  /* animation: fadeIn 1s ease forwards; */
  /* animation-delay: 0.5s; */
  width: 32px !important;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 9px;
}

.accordion {
  text-align: left;
  cursor: pointer;
  gap: 7px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px;
  border: none;
  padding-bottom: 10px;
}

.delete-bookmark {
  float: right;
  border: none;
  padding: 0px;
  /* display: none; */
  cursor: pointer;
  animation: fadeIn 1s cubic-bezier(0, 0, 0.2, 1) forwards;
}
.delete-bookmark .delete-active {
  display: none;
}
.delete-bookmark:hover .delete-active {
  display: inline-block;
  animation: fadeIn 0.5s ease forwards;
}
.delete-bookmark:hover .delete {
  display: none;
  animation: fadeIn 0.5s ease forwards;
}

.delete-bookmark .accordion.active {
  float: right;
  margin-left: 7px;
  border-radius: 100%;
  display: flex;
  padding: 0px;
  /* background: #007aff; */
  color: #007aff;
  justify-content: space-between;
  animation: fadeIn 0.5s ease forwards;
}

.open-arrow {
  float: right;
  padding: 0px 3px !important;
  margin-left: 7px !important;
}

.chat-messages.bookmark {
  padding: 0px;
}
.bookmark-message {
  margin-bottom: 0px;
  border-radius: 0px;
  filter: none;
  border-bottom: 1px solid #d8d8d8;
  background: #fff;
}

.accordion p {
  color: var(--text-body, #67748e);
  animation: fadeIn 0.5s cubic-bezier(0, 0, 0.2, 1) forwards;
}
/* .accordion {
  animation: fadeIn 0.5s cubic-bezier(0, 0, 0.2, 1) forwards;
} */

.timestamp-bookmark {
  color: var(--text-grey, #999);
  font-size: 12px;
  animation: fadeIn 0.5s cubic-bezier(0, 0, 0.2, 1) forwards;
  width: 80%;
}

.timestamp-bookmark,
.accordion p:nth-child(1) {
  margin: 0px !important;
  padding: 0px !important;
}

.accordion-content {
  animation: fadeIn 0.5s cubic-bezier(0, 0, 0.2, 1) forwards;
}
.open-arrow {
  animation: fadeIn 0.9s cubic-bezier(0, 0, 0.2, 1) forwards;
}

.sCWrapMinimize.CenterPop#zsitc_container {
  position: fixed;
  left: 49px;
  transform: inherit;
  bottom: inherit !important;
  top: calc(100vh - 36px);
}
.feedback-button {
  margin-right: 14px !important;
}
.feedback-button-icon {
  width: 20px;
}
.close-button-icon,
#authentication .close-button-icon,
.close-button-icon,
#onboarding .close-button-icon {
  width: 23px;
}
.refresh-button-icon,
#authentication .refresh-button-icon,
#onboarding .refresh-button-icon {
  width: 18px;
}

#authentication .close-button {
  margin-right: 0px;
}
.sCWrap {
  border-radius: calc(var(--borderRadius) + 2px);
}
.topBand .bandBar {
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
}

.tooltip {
  content: "Hi there! I'm Coach Losi, your sports and fitness companion! 🏋️‍♂️, Here...";
  position: absolute;
  bottom: 100%;
  left: -100px;
  transform: translateX(-50%);
  /* background: #4a4a4a; */
  background: #007aff;
  /* transition: gradient-change 1s linear infinite; */
  color: #fff;
  /* border: 1px solid #002ea3; */
  padding: 15px;
  width: 300px;
  margin-bottom: 5px;
  border-radius: 16px 16px 0px 16px;
  /* opacity: 0; */
  transition: opacity 1s;
  cursor: pointer;
  display: none;
  opacity: 0;
  text-align: left;
  box-shadow: var(--boxShadow);
  font-size: 17px;
  animation: fadeIn 0.5s cubic-bezier(0, 0, 0.2, 1) forwards;
}
/* .tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 1s;
  cursor: pointer;
  display: none;
} */

.red-dot {
  position: absolute;
  top: 6px;
  right: 4px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid;
  animation: fadeIn 0.5s cubic-bezier(0, 0, 0.2, 1) forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.closeTooltip {
  position: relative;
  bottom: 0;
  right: 24px;
  transform: translateX(-50%);
  background: #4a4a4a;
  /* background: #007aff; */
  color: #fff;
  z-index: 99999;
  border-radius: 50%;
  padding: 2px 13px;
  transition: opacity 1s;
  cursor: pointer;
  visibility: hidden;
  animation: fadeIn 0.5s cubic-bezier(0, 0, 0.2, 1) forwards;
}

.tooltip.shown {
  opacity: 1;
  display: inline;
}

.closeTooltip.shown {
  opacity: 1;
  visibility: visible;
  position: fixed;
  bottom: 23%;
  /* bottom: -13%; */
  right: 24px;
  transform: translateX(-50%);
  background: #4a4a4a;
  /* background: #007aff; */
  color: #fff;
  z-index: 99999;
  border-radius: 50px;
  padding: 2px 13px;
  transition: opacity 1s;
  cursor: pointer;
}
.tooltip.shown:hover,
.closeTooltip.shown:hover {
  filter: drop-shadow(0px 4px 4px#82d616);
  transition: all 0.5s ease;
}
.tooltip.fade-out,
.closeTooltip.fade-out {
  animation: fadeOut 0.3s;
  animation-fill-mode: forwards;
}
