@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  margin-top: 0;
}
.subTitle {
  /* @apply --marketplaceH1FontStyles; */
  margin-top: 0;
  text-align: center;
  width: 100%;
  @media (--viewportMedium) {
    width: 85%;
    text-align: left;
  }
}

.titleOuterDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
  }
}
.titleDiv {
  flex: 1;
}

.mockup {
  width: 100%;
  composes: animation;
  animation-delay: 0.9s;
  @media (--viewportMedium) {
    width: 375px;
    /* width: 40vw; */
    text-align: left;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  overflow-y: none;

  @media (--viewportMedium) {
    flex-direction: row;
    width: 100%;
  }
}
.toggleLosi {
  border: none;
  cursor: pointer;
}
/* .steps:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
} */
.icon {
  width: 40px;
  cursor: pointer;
}
.title,
.step p {
  text-align: center;
  @media (--viewportMedium) {
    text-align: left;
  }
}
.coachLosiRight {
  width: 240px;
  text-align: center;
  display: none;
  @media (--viewportMedium) {
    display: flex;
    text-align: left;
    width: 100%;
    /* width: 50%; */
    margin-right: 40px;
  }
}
.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media (--viewportMedium) {
    align-items: flex-start;
    width: 100%;
    /* width: 324px; */
    margin-right: 40px;
  }
}
.blue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: var(--matterColorBlue);
  margin-bottom: 25px;
  padding: 18px;
  padding-bottom: 0px;
  border-radius: var(--borderRadius);
  width: 100%;
  @media (--viewportMedium) {
    align-items: flex-start;
    width: 100%;
    padding: 25px;
    padding-bottom: 0px;
    /* width: 324px; */
    margin-right: 40px;
  }
}
.blues {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  background-color: var(--matterColorBlue);
  margin-bottom: 25px;
  padding: 18px;
  padding-bottom: 0px;
  border-radius: var(--borderRadius);
  width: 100%;
  text-align: start;
  @media (--viewportMedium) {
    align-items: flex-start;
    width: 100%;
    padding: 25px;
    padding-bottom: 0px;
    /* width: 324px; */
    margin-right: 25px;
  }
}

.blues h2 {
  text-align: start;
}
.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
  @media (--viewportMedium) {
    text-align: start;
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
    margin-top: 24px;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  /* composes: animation; */
  box-shadow: 0px 1px 20px 0px rgb(20 20 20 / 40%);

  animation-delay: 0.8s;
  border-radius: 16px;
  background: var(--marketplaceColor);
  display: block;

  color: white;
  &:hover {
    background: var(--marketplaceColor);
    color: white;
  }
  &:active {
    background: white;

    color: #007aff;
  }
  @media (--viewportMedium) {
    display: none;
    width: 260px;
  }
}
