@import '../../styles/propertySets.css';

.arrowClass {
    @apply --marketplaceButtonStyles;
    padding: 15px;
    margin: 15px;
    border-radius: 8px;
    height: 100px;
    width: 75px;
    align-items: center;
}