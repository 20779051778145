@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceButtonStyles;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}
svg {
  fill:none;
  margin-right:5px;
 
}

.primaryButtonRoot {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}
.buttons {
  display: inline-flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 18px;
  margin-bottom: 18px;
  flex-wrap: wrap;
}

.filterButton {
  @apply --marketplaceButtonFontStyles;

  /* Dimensions */
  display: block;
 width: 142px;


  min-height: 45px;
  margin: 0;
  BORDER-RADIUS: 8px;
  /* margin-right: 18px; */
  margin-bottom: 18px;
  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  /* padding: 6px 12px; */

  @media (--viewportMedium) {
    /* padding: 20px 0 20px 0; */
    
  }

  /* Borders */
  border: none;
 

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
    
  }
  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (--viewportSmall) {
    min-height: 45px;
    
  }
  background-color: rgba(0, 0, 0, 0);
  color: var(--marketplaceColor);
  border: solid 2px var(--marketplaceColor);
  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: #fbffff;
    border: solid 3px var(--marketplaceColor);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
    transform: scale(1.1);
  }

}

.filterButtons {

  /* border: none;
  border-radius: var(--borderRadius);
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  text-align: center;
  text-decoration: none;
  transition: all var(--transitionStyleButton);
  cursor: pointer;
  padding: 6px 12px;
  BORDER-RADIUS: 26px;

  &&:active{
    opacity: 7%;
  } */
  @apply --marketplaceButtonFontStyles;

  /* Dimensions */
  display: block;
  width: 112px;
  min-height: 59px;
  margin: 0;
  BORDER-RADIUS: 16px;
  margin: 10px;
  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  /* padding: 6px 12px; */

  @media (--viewportMedium) {
    justify-content: center;
  }

  /* Borders */
  border: none;
 

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }
  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (--viewportSmall) {
    min-height: 45px;
    justify-content: center;
    display: flex;
  }
}
.filterButton.active {
  opacity: 7%;
}

.secondaryButtonRoot {
  @apply --marketplaceButtonStylesSecondary;

  /* We must lift up the text from the center since it looks better with
   the button border. Also clears the top padding set for link
   elements looking like buttons */
  padding: 0 0 2px 0;
}

.inlineTextButtonRoot {
  @apply --marketplaceLinkStyles;
}

.primaryButton {
  /* Class handle for primary button state styles */
}
.secondaryButton {
  /* Class handle for secondary button state styles */
}

.inProgress {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.inProgress:disabled {
  background-color: var(--successColor);
}

.secondaryButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.ready {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.ready:disabled {
  background-color: var(--successColor);
}

.secondaryButton.ready:disabled {
  background-color: var(--matterColorLight);
}

.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

/* @keyframes checkmarkAnimation {
  0% {
    opacity: 1;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
} */
@keyframes checkmarkAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1.15);
  }
}

.secondaryButton .spinner {
  stroke: var(--matterColorAnti);
}

.secondaryButton .checkmark {
  stroke: var(--matterColorAnti);
}

/* Social logins && SSO buttons */

.socialButtonRoot {
  @apply --marketplaceButtonStyles;
  min-height: 48px;
  background-color: var(--matterColorLight);

  color: var(--matterColorDark);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;

  border: 1px solid #d2d2d2;
  border-radius: 4px;

  /* We need to add this to position the icon inside button */
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  &:hover,
  &:focus {
    background-color: var(--matterColorLight);
  }
  &:disabled {
    background-color: var(--matterColorNegative);
  }
}

.socialButton {
}
