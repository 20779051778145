@import '../../styles/propertySets.css';

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
}
.warning {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  font-style: italic;
  font-weight: lighter;
  flex-direction: column;
}
