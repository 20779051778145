@import '../../styles/propertySets.css';

/* Content is visible as modal layer */
.isOpen {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 12px;
  bottom: 0px;
  width: 432px;

  z-index: 9999;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    @apply --marketplaceModalRootStyles;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    /* background-color: var(--matterColorLight); */

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    /* min-height: 100vh; */
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      padding: 0;
      /* background-color: rgba(0, 0, 0, 0.4); */
      /* background-image: none; */
    }
  }

  & .container {
    @apply --marketplaceModalBaseStyles;
    /* height: 100%; */
    backdrop-filter: blur(18px);
    transition: background-color 0.3s ease;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
    padding: 0px;
    border-bottom: none;

    border-radius: var(--borderRadius);

    @media (--viewportMedium) {
      min-height: 80vh;
      padding: 0px;
      /* flex-basis: 576px;
      min-height: auto; */
      /* height: 807px; */
    }
  }
}

.content {
  height: 73vh;
  border-end-start-radius: var(--borderRadius);
  border-end-end-radius: var(--borderRadius);
}
.container h3 {
  padding: 20px 30px;
  margin: 0px;
  background-color: #002ea3;
  border-radius: var(--borderRadius);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: white;
}
/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  @apply --marketplaceModalCloseStyles;
  /* margin-top: 60px; */
  @media (--viewportMedium) {
    margin: 20px 0px;
    padding-top: 6px;
  }
  @media (--viewportLarge) {
    /* padding-top: 3px; */
  }
}

.closeText {
  @apply --marketplaceModalCloseText;
  display: none;
  color: white;
  @media (--viewportMedium) {
    display: block;
  }
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
  color: white;
}

.closeLight {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorLight);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}
