@import '../../styles/propertySets.css';


 .bar {
     width: 100%;
    margin-bottom: 12px;
    padding-bottom: 120px;
 }
 .indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    padding: 10px;
    background-color: rgba(139, 139, 139, 0.8);
    border-radius: 50%;
    display: flex !important ;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column !important ;
  }

h5 {
  position: absolute;
}
.img {
      border: solid 4px white;
      background-color: white;
      border-radius: 100px;

  }

  
  .indexedStep.accomplished {
    background-color: rgba(0, 116, 217, 1);
    display: flex;
    flex-direction: column;
  
  }

