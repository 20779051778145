@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.buttonCol {
  display: inline-flex;
}

.connectButton {
  width: 260px;
  border-radius: 10px;
  border: 1px solid #999999;
}
.connectButton:disabled:after {
  content: 'Connected with Zoom';
  padding-left: 5px;
}
.connectButton:inprogress {
  content: 'Connecting';
  color: black;
  padding-left: 5px;
}

.connectButton:disabled {
  background-color: #30c069;
}
.connectButton:disabled > svg {
  background-color: #fff;
  border-radius: 100px;
}

.connectButton:disabled > span {
  display: none;
}
.connectButton > span {
  padding-left: 5px;
}

.disconnectButton {
  width: 260px;
  border-radius: 10px;
  border: 1px solid #999999;
  color: white;
  background-color: #c03030;
  margin-left: 25px;
}
.disconnectButton:hover {
  width: 260px;
  border-radius: 10px;
  border: 1px solid #999999;
  color: white;
  background-color: #d34d4d;
}
.disconnectButton:disabled:after {
  content: 'Disconnected from Zoom';
  padding-left: 5px;
}
.disconnectButton:inprogress {
  content: 'Connecting';
  color: black;
  padding-left: 5px;
}

.disconnectButton:disabled {
  display: none;
}
.disconnectButton:disabled > svg {
  background-color: #fff;
  border-radius: 100px;
}

.disconnectButton:disabled > span {
  display: none;
}
.disconnectButton > span {
  padding-left: 5px;
}
.title {
  margin-bottom: 3px;
}
.subtitle {
  margin-bottom: 16px;
}
.warning {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}
.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
