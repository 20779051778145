@import '../../styles/propertySets.css';

.root {
  margin: 0;
}


.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 42px;
    margin-bottom: 6px;
    border: 1px solid #D2D6DA;
    border-radius: 8px;
    color: #67748E;
  
  @media (--viewportMedium) {
    padding: 0 42px;
    margin-bottom: 6px;
  }
}

.group {
    padding-top: 6px;
    /* background: #F3FCFF; */
    /* box-shadow: -3px 0px 20px rgb(20 20 20 / 14%); */
    border-radius: 16px;
    margin: 10px 0;
    text-align: center;
    @media (--viewportMedium) {
      margin: 16px 120px;
      }
}
.left,
.center,
.right {
    font-size: 14px;
    font-weight: lighter;
    width: 164px;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
  font-weight: 500;
}
.right {
  text-align: right;
  font-weight: 600;
}
.header {
  color: #000000;
  text-align: left;
  margin-bottom: 6px;
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}
.tableHeader {
  display: flex;
  justify-content: space-between;
  padding: 4px 42px;
  color: #252F40;
  padding-bottom: 0px;
  margin-bottom: 0px;
  
}
.tableHeaderItem {
  width: 100px;
  
}

.tableHeaderItem:nth-child(1){
  text-align: left ;
}
.tableHeaderItem:nth-child(2){
  text-align: center ;
}
.tableHeaderItem:nth-child(3){
  text-align: right ;
}



.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  @apply --marketplaceBodyFontStyles;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
}

.notSelectedLabel {
  color: var(--matterColorNegative);
  position: relative;

  /* line-through */
  background-image: linear-gradient(
    transparent 10px,
    var(--matterColorNegative) 10px,
    var(--matterColorNegative) 11px,
    transparent 11px
  );

  @media (--viewportMedium) {
    background-image: linear-gradient(
      transparent 12px,
      var(--matterColorNegative) 12px,
      var(--matterColorNegative) 13px,
      transparent 13px
    );
  }
}
