@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.pageTitle {
  text-align: center;
  @apply --marketplaceH1FontStyles;
  font-size: clamp(2.032rem, 2.032rem + ((1vw - 0.2rem) * 2.896), 3.625rem);
  line-height: 1.2;
  @media (--viewportMedium) {
    text-align: left;
  }
}

article blockquote {
  margin-right: 0px;
}
.staticPageWrapper {
  /* width: calc(100% - 48px); */
  max-width: 90%;
  width: 90%;
  margin: 24px auto;

  @media (--viewportMedium) {
    /* width: calc(100% - 72px); */
    max-width: 1056px;
    margin: 72px auto;
  }
}

/* div:has(> img) {
  display: flex;
  justify-content: center;
} */

.contentMain img {
  width: 100% !important; /* //TODO: Remove temporary fix after blogs have been properly formatted */
  border-radius: var(--borderRadius);
  object-fit: cover;
  margin: 16px 0px 16px !important;
  @media (--viewportMedium) {
    margin: 32px 0 32px !important;

    /* padding: 32px; */
    height: 528px;
  }
}
.contentMain figure {
  width: 100% !important; /* //TODO: Remove temporary fix after blogs have been properly formatted */
  /* margin: 16px !important; */
  margin: 16px 0px 16px !important;
  @media (--viewportMedium) {
    margin: 32px 0 32px !important;
  }
}
.PostListChallenges h4 span,
.blogPage h4 span,
.blogPage h4,
.PostListChallenges h4 {
  @apply --marketplaceH4FontStyles;
  font-size: clamp(1.5rem, 1.5rem + ((1vw - 0.4rem) * 0.427), 2rem) !important;
}
.PostListChallenges h3,
.blogPage h3 {
  @apply --marketplaceH3FontStyles;
  font-size: clamp(1.75rem, 1.75rem + ((1vw - 0.2rem) * 0.227), 1.875rem) !important;
  line-height: 1.2 !important;
}

.postDateContainer {
  display: flex;
}
.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 100%;

  @media (--viewportMedium) {
    /* max-width: 650px; */
  }
}

.subtitle {
  @apply --marketplaceSmallFontStyles;
  margin-top: 32px;
  margin-bottom: 16px;
  font-weight: 500;
}

/* .PostListChallenges {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  padding: 20px;
} */
.PostListChallenges {
  display: grid;
  gap: 40px;
  align-self: center;
  justify-content: center;
  /* grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); */
  @media (--viewportMedium) {
    padding: 20px 0;
  }
}
.header,
.subtitle {
  text-align: center;
  @media (--viewportMedium) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.header span {
  color: var(--marketplaceColor);
}
.postLink {
  text-decoration: none;
  color: inherit;
  width: 87vw;
  align-items: center;
  @media (--viewportMedium) {
    width: 100%;
  }
}

.card {
  border: 1px solid #eee;
  border-radius: 16px;
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;
}

.card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.cardContent {
  padding: 20px;
}

.postTitle {
  margin: 0;
  color: var(--marketplaceColor);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.postImage {
  width: 100%;
  height: auto;
  margin-top: 15px;
  border-radius: 10px;
}

.postExcerpt {
  font-size: 1rem;
  margin-top: 15px;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}
h6.postDate {
  @apply --marketplacePFontStyles;
  color: var(--marketplaceColor);
  font-weight: 500;

  /* font-size: 0.8rem; */
  padding-bottom: 0px !important;
  /* margin-top: 25px; */
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}
.postDatePreText {
  @apply --marketplacePFontStyles;
  font-weight: 300;
  font-size: 0.8rem;
  padding-right: 4px;
  padding-bottom: 0px !important;
  /* margin-top: 25px; */
}
article li {
  padding-left: 1em;
  text-indent: -0.7em;
}

article li::before {
  content: '• ';
  color: var(--marketplaceColor);
  font-size: 1.2rem;
}

article ::marker,
article summary::-webkit-details-marker {
  color: var(--marketplaceColor);
  margin-left: 0.3em;
}
article li ul {
  list-style: none;
  margin-left: 1.9em;
}
article > div > blockquote > details > summary > strong {
  color: var(--marketplaceColor);
}

article > div > blockquote > details > summary {
  cursor: pointer;
}
.cardWrapper {
  position: relative;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 16px;
  transition: box-shadow 0.3s ease-in-out;
  transition: transform 0.2s;
  overflow: hidden;
}
.cardWrapper:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  transform: scale(1.03);
}

.cardBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  transition: transform 0.2s;
}

.cardContent {
  position: relative;
  background-color: rgba(255, 255, 255, 0.85);

  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.cardContent img {
  @media (--viewportMedium) {
    width: 30%;
  }
}
.cardContent .Left {
  @media (--viewportMedium) {
    width: 70%;
    padding-right: 40px;
  }
}
.postTitle,
.postExcerpt {
  margin: 0;
}

.postLink {
  text-decoration: none;
  color: inherit;
}

.postLink:hover {
  text-decoration: none;
  color: inherit;
}

.blogPage hr {
  width: 100%;
  margin-top: 2rem;
}
