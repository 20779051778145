@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  @apply --coachLosiBackgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  border-radius: 0 0 var(--borderRadius) var(--borderRadius);
  @media (--viewportMedium) {
    height: 100%;
  }
}

.heroContent {
  margin: 12px 24px 48px 24px;

  @media (--viewportMedium) {
    margin: 2vh 15vw 0 15vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--marketplaceColor);
  text-align: center;
  composes: animation;
  animation-delay: 0.5s;
  margin-bottom: 32px;
  @media (--viewportMedium) {
    text-align: left;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
  @media only screen and (max-width: 350px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 320px) {
    font-size: 29px;
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;
  text-align: center;
  color: var(--marketplaceColor);
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    text-align: left;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  background-color: var(--matterColorBlue);
  box-shadow: 0px 1px 20px 0px rgb(20 20 20 / 40%);

  background: var(--marketplaceColor);
  color: white;
  animation-delay: 0.8s;
  border-radius: 16px;
  &:hover {
    color: var(--attentionColorLight);
    background: var(--marketplaceColorAlt);
  }
  &:active {
    /* background: #007aff; */
    background: var(--matterColor);

    color: white;
  }
  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
.heroButtonBlue {
  @apply --marketplaceButtonStyles;
  composes: animation;
  box-shadow: 0px 1px 20px 0px rgb(20 20 20 / 40%);

  animation-delay: 0.8s;
  border-radius: 16px;
  background: #007aff;

  color: white;
  &:hover {
    background: var(--marketplaceColor);
    color: white;
  }
  &:active {
    background: white;

    color: #007aff;
  }
  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.disabledButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  box-shadow: 0px 1px 20px 0px rgb(20 20 20 / 40%);

  animation-delay: 0.8s;
  border-radius: 16px;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.buttonContainer {
  display: flex;
  gap: 16px;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.title3 {
  font-weight: 600;
}
.titleCont {
  display: inline-flex;
  gap: 16px;
}
