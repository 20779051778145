@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  margin-bottom: 0 !important;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.sports {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}
.showMore {
  @apply --marketplaceButtonStylesPrimary;
  width: 100%;
  flex-basis: 45%;
  position: relative;
  filter: drop-shadow(0px 4px 6px rgba(20, 20, 20, 0.12))
    drop-shadow(0px 2px 4px rgba(20, 20, 20, 0.07)) /* Remove link's hover effect */;
  background-color: var(--marketplaceColor);
  border: none;
}

.location {
  width: 100%;
  padding-bottom: 25px;
  flex-basis: 45%;
  position: relative;
  filter: drop-shadow(0px 4px 6px rgba(20, 20, 20, 0.12))
    drop-shadow(0px 2px 4px rgba(20, 20, 20, 0.07));
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    transform: scale(1.02);
  }

  @media (--viewportMedium) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.location {
  @media (--viewportMedium) {
    /* margin-right: 40px; */
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 8px;
  border-left: solid;
  transition: var(--transitionStyleButton);
  overscroll-behavior: contain;
  overflow: hidden;
  object-fit: fill;
  &:hover {
    /* transform: scale(1.01); */
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */

  /* filter: brightness(0.95); */
  object-fit: fill;
  width: -webkit-fill-available;
  transform: scale(1.1);
  @media (--viewportMedium) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: none;
    border-radius: 8px;
  }
}

.aspectWrapper img {
  &:hover {
    filter: brightness(0.8);
    transform: scale(1.15);
  }
  &:active {
    filter: brightness(0.8);
  }
}

.linkText {
  @apply --marketplaceH2FontStyles;
  backdrop-filter: blur(8px);
  filter: drop-shadow(0px 8px 8px rgba(20, 20, 20, 0.12))
    drop-shadow(0px 2px 4px rgba(20, 20, 20, 0.07));
  /* background: #f3fcffe2; */
  background: #ffffffa2;
  border: 2px var(--marketplaceColor) solid;
  border-left: none;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;
  position: absolute;
  top: 15px;
  padding: 10px 48px 10px 28px;

  border-radius: 0px 28px 28px 0px;
  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

/* filter button css starts here */

.primaryButtonRoot {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}
.buttons {
  display: inline-flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 9px;
  margin-bottom: 18px;
  flex-wrap: wrap;
}

.filterButtonAll {
  @apply --marketplaceButtonFontStyles;

  /* Dimensions */
  display: block;
  width: 112px;
  min-height: 59px;
  margin: 0;
  border-radius: 36px;
  margin: 10px;
  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  /* padding: 6px 12px; */

  @media (--viewportMedium) {
    /* padding: 20px 0 20px 0; */
  }

  /* Borders */
  border: none;

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }
  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (--viewportMedium) {
    min-height: 65px;
  }
  background-color: rgba(0, 0, 0, 0);
  color: var(--marketplaceColor);
  border: solid 5px var(--marketplaceColor);
  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: var(--marketplaceColorLight);
    border: solid 5px var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }
}

.filterButton {
  /* border: none;
  border-radius: var(--borderRadius);
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  text-align: center;
  text-decoration: none;
  transition: all var(--transitionStyleButton);
  cursor: pointer;
  padding: 6px 12px;
  BORDER-RADIUS: 26px;

  &&:active{
    opacity: 7%;
  } */
  @apply --marketplaceButtonFontStyles;

  /* Dimensions */
  display: block;
  width: 112px;
  min-height: 59px;
  margin: 0;
  border-radius: 16px;
  margin: 10px;
  /* Padding is only for <a> elements where button styles are applied,
  buttons elements should have zero padding */
  /* padding: 6px 12px; */

  @media (--viewportMedium) {
    padding: 20px 0 20px 0;
  }

  /* Borders */
  border: none;

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }
  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (--viewportMedium) {
    min-height: 65px;
  }
}
.filterButton.active {
  opacity: 7%;
}

.secondaryButtonRoot {
  @apply --marketplaceButtonStylesSecondary;

  /* We must lift up the text from the center since it looks better with
   the button border. Also clears the top padding set for link
   elements looking like buttons */
  padding: 0 0 2px 0;
}

.inlineTextButtonRoot {
  @apply --marketplaceLinkStyles;
}

.primaryButton {
  /* Class handle for primary button state styles */
}
.secondaryButton {
  /* Class handle for secondary button state styles */
}

.inProgress {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.inProgress:disabled {
  background-color: var(--successColor);
}

.secondaryButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.ready {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.ready:disabled {
  background-color: var(--successColor);
}

.secondaryButton.ready:disabled {
  background-color: var(--matterColorLight);
}

.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.secondaryButton .spinner {
  stroke: var(--matterColorAnti);
}

.secondaryButton .checkmark {
  stroke: var(--matterColorAnti);
}

/* Social logins && SSO buttons */

.socialButtonRoot {
  @apply --marketplaceButtonStyles;
  min-height: 48px;
  background-color: var(--matterColorLight);

  color: var(--matterColorDark);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;

  border: 1px solid #d2d2d2;
  border-radius: 4px;

  /* We need to add this to position the icon inside button */
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  &:hover,
  &:focus {
    background-color: var(--matterColorLight);
  }
  &:disabled {
    background-color: var(--matterColorNegative);
  }
}

.socialButton {
}
